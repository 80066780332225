export const environment = {
  production: false,
  ENV: 'acc',
  VERSION: 'v4.122.4',

  BASE_URL_BCOS: 'https://api.blanco-acc.cloud/bcos',
  BASE_URL_BKYC: 'https://api.blanco-acc.cloud/bkyc',
  BASE_URL_BPARTY: 'https://api.blanco-acc.cloud/bcmd-party',
  BASE_URL_BCUS: 'https://api.blanco-acc.cloud/custodians',
  BASE_URL_BAMS: 'https://api.blanco-acc.cloud/access',
  BASE_URL_BGC: 'https://api.blanco-acc.cloud/bgc',
  BASE_URL_BTP: 'https://api.blanco-acc.cloud/btp/',
  BASE_URL_BSUR: 'https://api.blanco-acc.cloud/bsur/',
  THEME_URL: 'https://assets.blanco-acc.cloud/themes',

  BASE_URL_WSS: 'wss://7uh4wwtiw8.execute-api.eu-central-1.amazonaws.com/acc',

  POSTCODE_X_API_KEY: 'TJMzrIw1fk2XyeAms0pN86tZC6n2Y7J9RkGmS879',

  BUGSNAG_API: '0adf26b3d9db4b357ab11ffc9e2d95ce',

  redirectTo: {
    clientPortal: '.blanco-acc.cloud'
  },
};
